"use client";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/GlobalRedux/store";
import { jwtDecode } from "jwt-decode";
import cn from "classnames";

import { Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setAuthenticationPhase,
  setListsInformation,
} from "../../app/GlobalRedux/Features/authSlice";
import { AuthPhase } from "@/types";
import { useRouter } from "next/navigation";
import SpawningArrow from "../../../public/assets/icons/SpawningArrow";
import mixpanel from "mixpanel-browser";
import AuthService from "@/services/authService";

import styles from "./AccountMenu.module.scss";
import { setSnackBar } from "@/app/GlobalRedux/Features/snackBarSlice";
import AccountAvatar from "../AccountAvatar/AccountAvatar";
import SpawningLogo from "../../../public/assets/icons/SpawningLogo";

const AccountMenu = () => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const router = useRouter();
  const [userEmail, setUserEmail] = useState<string>("");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route: string) => {
    router.push(route);
  };

  useEffect(() => {
    if (!userInfo) return;

    try {
      // this has to be typed as any because JwtDecode doesnt have an email property
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { email } = jwtDecode(userInfo.id_token) as any;
      setUserEmail(email);
    } catch (error) {
      console.error(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div onClick={handleClick} className={styles.accountMenuDisplay}>
        <div className={styles.logoWrapper}>
          <SpawningLogo />
        </div>
        <div className={styles.avatar}>
          <AccountAvatar email={userEmail} />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            border: "1px solid var(--primary-07)",
            borderRadius: "16px",
            boxShadow: "none",
            margin: "8px 0",
          },
        }}
      >
        <div>
          <MenuItem className={cn(styles.menuItem, styles.emailWrapper)}>
            <a href={`/settings`}>
              <div className={styles.menuItemContent}>
                <SpawningArrow />
                {userEmail}
              </div>
            </a>
          </MenuItem>
          <MenuItem className={styles.menuItem}>
            <a href={`/dashboard`}>
              <div className={styles.menuItemContent}>
                <SpawningArrow />
                Dashboard
              </div>
            </a>
          </MenuItem>

          <MenuItem
            className={styles.menuItem}
            onClick={() => handleNavigation("/api-key")}
          >
            <div className={styles.menuItemContent}>
              <SpawningArrow />
              API key
            </div>
          </MenuItem>

          <MenuItem
            className={styles.menuItem}
            onClick={() => {
              window.open("mailto:info@spawning.ai");

              mixpanel.track("Help button clicked");
            }}
          >
            <div className={styles.menuItemContent}>
              <SpawningArrow />
              Help
            </div>
          </MenuItem>
          <MenuItem className={styles.menuItem}>
            <a href={`/settings`}>
              <div className={styles.menuItemContent}>
                <SpawningArrow />
                Settings
              </div>
            </a>
          </MenuItem>
          <MenuItem
            className={styles.menuItem}
            onClick={() => {
              try {
                AuthService.logout(userInfo?.access_token || "")
                  .then((resp) => {
                    dispatch(setAuthenticationPhase(AuthPhase.LOGOUT));
                    dispatch(setListsInformation(undefined));
                  })
                  .catch((err) => {
                    dispatch(
                      setSnackBar({
                        open: true,
                        message: err.message,
                        variant: "error",
                      })
                    );
                  });
              } catch (error) {
                dispatch(
                  setSnackBar({
                    open: true,
                    message: error,
                    variant: "error",
                  })
                );
              }
            }}
          >
            <div className={styles.menuItemContent}>
              <SpawningArrow />
              Sign out
            </div>
          </MenuItem>

          <MenuItem className={cn(styles.menuItem, styles.spawningWrapper)}>
            <a
              href="https://spawning.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.menuItemContent}>
                <SpawningArrow />
                Spawning
              </div>
            </a>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
};

export default AccountMenu;
