import React from "react";
import PortraitIcon from "@mui/icons-material/Portrait";

import styles from "./AccountAvatar.module.scss";

interface AccountAvatarProps {
  email: string;
  username?: string;
}

const AccountAvatar: React.FC<AccountAvatarProps> = ({ email }) => {
  const avatarDisplayValue = email?.slice(0, 1);

  return (
    <div className={styles.avatar}>
      {avatarDisplayValue || <PortraitIcon />}
    </div>
  );
};

export default AccountAvatar;
