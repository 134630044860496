import React from "react";

const SpawningLogo = () => (
  <svg
    width="2657"
    height="467"
    viewBox="0 0 2657 467"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_237_26"
      maskUnits="userSpaceOnUse"
      x="772"
      y="33"
      width="1885"
      height="434"
    >
      <path d="M2657 33H772V467H2657V33Z" fill="white" />
    </mask>
    <g mask="url(#mask0_237_26)">
      <path
        d="M916.785 379.415C830.585 379.415 775.341 341.346 772 263.303C772 261.397 773.908 259.492 775.815 259.492H829.151C831.059 259.492 832.966 261.397 833.44 263.303C837.255 306.13 858.2 333.249 918.206 333.249C964.4 333.249 993.93 318.5 993.93 281.378C993.93 199.05 778.67 261.871 778.67 131.484C778.67 70.1218 827.243 33 906.774 33C986.3 33 1034.4 64.4039 1042.98 134.349C1043.45 136.255 1041.55 138.161 1039.65 138.161H986.32C984.41 138.161 982.5 136.729 982.03 134.349C978.21 98.66 952.505 79.6261 904.879 79.6261C864.397 79.6261 836.295 93.902 836.295 125.779C836.295 203.348 1052.03 142.434 1052.03 273.294C1052.03 342.766 998.21 379.415 916.773 379.415H916.785Z"
        fill="currentColor"
      />
      <path
        d="M1191.58 377.509C1159.68 377.509 1134.9 365.612 1118.24 342.766L1119.18 449.833C1119.18 451.739 1117.28 453.645 1115.37 453.645H1064.41C1062.51 453.645 1060.6 451.739 1060.6 449.833L1062.03 269.968L1060.6 131.497C1060.6 129.591 1062.51 127.685 1064.41 127.685H1114.89C1116.8 127.685 1118.71 129.591 1118.71 131.497L1117.28 160.995C1134.42 137.202 1159.66 122.926 1192.53 122.926C1260.63 122.926 1296.35 178.123 1296.35 249.501C1296.35 320.879 1257.3 377.509 1191.57 377.509H1191.58ZM1175.86 336.588C1217.78 336.588 1238.25 305.184 1238.25 252.84C1238.25 197.17 1217.76 164.333 1177.28 164.333C1136.8 164.333 1116.31 192.885 1115.84 248.081C1115.37 304.711 1137.27 336.588 1175.85 336.588H1175.86Z"
        fill="currentColor"
      />
      <path
        d="M1482.09 372.75C1480.18 372.75 1478.27 370.844 1478.27 368.938L1479.22 338.954C1462.55 363.22 1436.83 377.496 1403.98 377.496C1335.87 377.496 1300.16 322.299 1300.16 250.921C1300.16 179.543 1339.21 122.914 1404.94 122.914C1437.33 122.914 1462.56 135.283 1479.23 159.076L1477.8 131.471C1477.8 129.565 1479.71 127.659 1481.61 127.659H1532.09C1534 127.659 1535.91 129.565 1535.91 131.471L1534.48 249.476L1535.91 368.913C1535.91 370.818 1534 372.724 1532.09 372.724H1482.09V372.75ZM1419.23 337.061C1457.8 337.061 1480.19 308.51 1480.67 251.88C1481.14 195.251 1459.23 163.847 1420.66 163.374C1378.74 162.901 1358.27 194.778 1358.27 247.122C1358.27 304.224 1379.23 337.061 1419.24 337.061H1419.23Z"
        fill="currentColor"
      />
      <path
        d="M1755.94 372.75C1753.55 372.75 1751.65 371.317 1751.17 368.938L1705.93 176.217L1665.92 368.938C1665.45 371.317 1663.07 372.75 1661.16 372.75H1601.63C1599.24 372.75 1597.34 371.317 1596.86 368.938L1538.76 131.497C1538.29 129.591 1539.71 127.685 1541.62 127.685H1592.1C1594.01 127.685 1596.39 129.118 1596.86 131.497L1634.02 324.691L1674.5 131.497C1674.97 129.118 1677.35 127.685 1679.26 127.685H1740.7C1742.61 127.685 1744.99 129.118 1745.46 131.497L1786.42 320.879L1823.57 131.497C1824.05 129.118 1826.43 127.685 1828.33 127.685H1873.58C1875.49 127.685 1876.91 129.591 1876.43 131.497L1820.72 368.938C1820.24 371.317 1818.34 372.75 1815.95 372.75H1755.95H1755.94Z"
        fill="currentColor"
      />
      <path
        d="M1883.58 372.75C1881.67 372.75 1879.76 370.844 1879.76 368.938L1881.2 257.586L1880.72 131.484C1880.72 129.578 1882.63 127.672 1884.54 127.672H1932.64C1934.55 127.672 1936.45 129.578 1936.45 131.484L1935.02 163.361C1950.74 140.515 1978.83 121.967 2015.51 121.967C2066.46 121.967 2099.33 156.223 2099.33 218.084V268.523L2100.28 368.925C2100.28 370.831 2098.37 372.737 2096.46 372.737H2045.98C2044.07 372.737 2042.16 370.831 2042.16 368.925L2043.11 268.523V219.504C2043.11 184.762 2024.54 166.687 1998.34 166.687C1969.77 166.687 1942.62 190.953 1937.86 241.864V268.51L1938.81 368.913C1938.81 370.819 1936.9 372.724 1934.99 372.724H1883.55L1883.58 372.75Z"
        fill="currentColor"
      />
      <path
        d="M2120.26 102.472C2118.35 102.472 2116.45 100.566 2116.45 98.6598V49.6548C2116.45 47.7488 2118.35 45.8428 2120.26 45.8428H2170.74C2172.65 45.8428 2174.56 47.7488 2174.56 49.6548V98.6728C2174.56 100.579 2172.65 102.485 2170.74 102.485H2120.26V102.472ZM2120.26 372.75C2118.35 372.75 2116.45 370.844 2116.45 368.938L2117.39 248.555L2116.45 131.497C2116.45 129.591 2118.35 127.685 2120.26 127.685H2171.22C2173.12 127.685 2175.03 129.591 2175.03 131.497L2173.6 248.555L2175.03 368.938C2175.03 370.844 2173.12 372.75 2171.22 372.75H2120.26Z"
        fill="currentColor"
      />
      <path
        d="M2196.95 372.75C2195.04 372.75 2193.13 370.844 2193.13 368.938L2194.57 257.586L2194.09 131.484C2194.09 129.578 2196 127.672 2197.91 127.672H2246.01C2247.92 127.672 2249.82 129.578 2249.82 131.484L2248.39 163.361C2264.11 140.515 2292.2 121.967 2328.88 121.967C2379.84 121.967 2412.7 156.223 2412.7 218.084V268.523L2413.65 368.925C2413.65 370.831 2411.74 372.737 2409.83 372.737H2359.35C2357.44 372.737 2355.54 370.831 2355.54 368.925L2356.48 268.523V219.504C2356.48 184.762 2337.91 166.687 2311.71 166.687C2283.14 166.687 2256 190.953 2251.23 241.864V268.51L2252.18 368.913C2252.18 370.819 2250.27 372.724 2248.36 372.724H2196.92L2196.95 372.75Z"
        fill="currentColor"
      />
      <path
        d="M2541.28 466.974C2480.31 467.921 2434.12 442.708 2429.83 388.932C2429.83 387.026 2431.74 385.12 2433.64 385.12H2482.7C2484.61 385.12 2486.52 386.552 2486.99 388.932C2490.32 414.157 2510.81 427 2542.71 427C2578.43 427 2602.72 407.019 2602.72 360.854V332.776C2586.52 357.042 2561.76 371.317 2529.37 371.317C2458.41 371.317 2421.74 318.5 2421.74 249.028C2421.74 179.556 2460.79 123.886 2526.99 123.886C2559.38 123.886 2584.14 136.255 2600.34 159.101L2599.39 131.497C2599.39 129.591 2601.3 127.685 2603.2 127.685H2653.21C2655.12 127.685 2657.03 129.591 2657.03 131.497L2655.59 241.417L2656.07 361.813C2656.54 427.473 2616.53 466.974 2541.29 466.974H2541.28ZM2541.75 331.829C2579.38 331.829 2601.28 305.184 2601.76 250.934C2602.23 195.264 2580.8 164.333 2541.75 163.847C2500.79 162.901 2479.84 194.305 2479.84 246.649C2479.84 302.318 2501.27 332.302 2541.75 331.829Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M41.7676 123.949C32.931 123.949 25.7676 131.113 25.7676 139.949C25.7676 148.786 32.931 155.949 41.7676 155.949V123.949ZM41.7676 155.949H226.561V123.949H41.7676V155.949Z"
      fill="currentColor"
    />
    <path
      d="M314.72 51.0493C305.883 51.0493 298.72 58.2127 298.72 67.0493C298.72 75.8858 305.883 83.0493 314.72 83.0493V51.0493ZM314.72 83.0493H672.439V51.0493H314.72V83.0493Z"
      fill="currentColor"
    />
    <path
      d="M314.72 198.545C305.883 198.545 298.72 205.708 298.72 214.545C298.72 223.381 305.883 230.545 314.72 230.545V198.545ZM314.72 230.545H672.439V198.545H314.72V230.545Z"
      fill="currentColor"
    />
    <path
      d="M314.72 346.041C305.883 346.041 298.72 353.204 298.72 362.041C298.72 370.877 305.883 378.041 314.72 378.041V346.041ZM314.72 378.041H672.439V346.041H314.72V378.041Z"
      fill="currentColor"
    />
    <path
      d="M41.7676 273.14C32.931 273.14 25.7676 280.304 25.7676 289.14C25.7676 297.977 32.931 305.14 41.7676 305.14V273.14ZM41.7676 305.14H226.561V273.14H41.7676V305.14Z"
      fill="currentColor"
    />
    <path
      d="M122.857 141.859C124.498 142.531 124.518 144.847 122.889 145.547L59.516 172.789C59.361 172.856 59.215 172.942 59.082 173.045L4.5321 215.265C3.13 216.35 1.1138 215.209 1.3218 213.449L9.4161 144.945C9.4359 144.778 9.43441 144.608 9.41171 144.441L0.123104 76.0894C-0.115696 74.3325 1.8804 73.1567 3.3012 74.2173L58.58 115.479C58.715 115.58 58.862 115.663 59.018 115.727L122.857 141.859Z"
      fill="currentColor"
    />
    <path
      d="M382.246 68.9587C383.887 69.6304 383.907 71.9469 382.278 72.6471L318.906 99.8891C318.751 99.9551 318.605 100.041 318.471 100.145L263.921 142.365C262.519 143.45 260.503 142.309 260.711 140.548L268.805 72.0451C268.825 71.8776 268.824 71.7082 268.801 71.5411L259.512 3.18943C259.274 1.43253 261.27 0.256825 262.69 1.31743L317.969 42.5789C318.104 42.6798 318.251 42.7632 318.408 42.8271L382.246 68.9587Z"
      fill="currentColor"
    />
    <path
      d="M382.246 363.95C383.887 364.622 383.907 366.938 382.278 367.639L318.906 394.88C318.751 394.947 318.605 395.033 318.471 395.136L263.921 437.356C262.519 438.441 260.503 437.301 260.711 435.54L268.805 367.037C268.825 366.869 268.824 366.7 268.801 366.533L259.512 298.181C259.274 296.424 261.27 295.248 262.69 296.309L317.969 337.57C318.104 337.671 318.251 337.755 318.408 337.819L382.246 363.95Z"
      fill="currentColor"
    />
    <path
      d="M124.552 289.355C126.193 290.026 126.213 292.343 124.584 293.043L61.212 320.285C61.057 320.351 60.911 320.437 60.777 320.541L6.2274 362.761C4.8253 363.846 2.8091 362.705 3.0171 360.944L11.1114 292.441C11.1312 292.274 11.1297 292.104 11.107 291.937L1.8184 223.585C1.5797 221.828 3.5757 220.653 4.9965 221.713L60.275 262.975C60.41 263.076 60.558 263.159 60.714 263.223L124.552 289.355Z"
      fill="currentColor"
    />
    <path
      d="M383.941 216.454C385.582 217.126 385.603 219.443 383.974 220.143L320.601 247.385C320.446 247.451 320.3 247.537 320.167 247.64L265.617 289.86C264.214 290.946 262.198 289.805 262.406 288.044L270.501 219.541C270.52 219.373 270.519 219.204 270.496 219.037L261.208 150.685C260.969 148.928 262.965 147.753 264.386 148.813L319.664 190.075C319.799 190.176 319.947 190.259 320.103 190.323L383.941 216.454Z"
      fill="currentColor"
    />
  </svg>
);

export default SpawningLogo;
