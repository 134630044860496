import React, { useEffect, useState } from "react";
import { ClickAwayListener, TextField } from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import AuthService from "@/services/authService";
import { useDispatch } from "react-redux";

import {
  setAuthenticationPhase,
  setUserInformation,
} from "../../../app/GlobalRedux/Features/authSlice";
import { AuthPhase, ChallengeType, SessionChallenge } from "@/types";
import styles from "./LogInModal.module.scss";
import Button from "@/components/Button/Button";
import { setAuthModalsState } from "../../../app/GlobalRedux/Features/authModalsSlice";
import PasswordTextField from "@/components/PasswordFields/PasswordTextField/PasswordTextField";
import Logging from "@/utils/Logging";
import GoogleIcon from "../../../../public/assets/icons/GoogleIcon";
import { jwtDecode } from "jwt-decode";

type FormValues = {
  username: string;
  password: string;
};

const LogInModal = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const dispatch = useDispatch();
  const [isComplete, setIsComplete] = useState(true);
  const [formError, setFormError] = useState("");

  const handleSetDispatch = (authPhase: AuthPhase) => {
    dispatch(setAuthenticationPhase(authPhase));
  };

  const handleGoogleLogin = () => {
    const url: string = AuthService.getOAuthUrl("google");
    window.open(url, "_self");
  };

  useEffect(() => {
    // get error message from url and display it if present
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get("error_description");
    if (error) {
      setFormError(error);
    }
  }, []);

  const handleFormSubmit: SubmitHandler<FormValues> = async ({
    username,
    password,
  }) => {
    try {
      const response = await AuthService.login(
        username,
        password,
        (challenge: ChallengeType | undefined, params: SessionChallenge) => {
          setFormError("");
          setIsComplete(false);

          if (challenge === ChallengeType.COMPLETE) {
            handleSetDispatch(AuthPhase.DONE);

            // this has to be typed as any because JwtDecode doesnt have an email property
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { email } = jwtDecode(params.id_token || "") as any;

            // IMPORTANT!
            // Use the access_token to talk to the auth endpoints
            // use the id_token to talk to the HIBT endpoints

            dispatch(
              setUserInformation({
                // set this to an empty string so it doesn't initially set
                // the username as an email if that is what the user uses to log in
                username: "",
                access_token: params.access_token,
                id_token: params.id_token,
                refresh_token: params.refresh_token,
                email,
              })
            );

            setIsComplete(true);
          } else if (challenge === "NEW_PASSWORD_REQUIRED") {
            // this has to be typed as any because JwtDecode doesnt have an email property
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { email } = jwtDecode(params.id_token || "") as any;

            dispatch(
              setUserInformation({
                // set this to an empty string so it doesn't initially set
                // the username as an email if that is what the user uses to log in
                username: "",
                access_token: params.access_token,
                id_token: params.id_token,
                refresh_token: params.refresh_token,
                session: params.session,
                email,
              })
            );

            handleSetDispatch(AuthPhase.NEW_PASSWORD);
          } else if (challenge === "SOFTWARE_TOKEN_MFA") {
            handleSetDispatch(AuthPhase.MFA_CHALLENGE);
          }

          return { challenge, parameters: params };
        }
      );

      if (response?.remedy) {
        setFormError(response ? response.remedy : "");
        setIsComplete(true);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.remedy;
        setFormError(errorMessage);

        return;
      }

      setFormError(
        "There was an unknown error logging in. Please try again or contact us at support@spawning.ai."
      );

      setIsComplete(true);

      Logging.networkError("API Error - authService.login", {
        pageName: "LoginModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <ClickAwayListener
        onClickAway={() => {
          dispatch(setAuthModalsState({ isLogInModalOpen: false }));
        }}
      >
        <div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles.content}>
              <div>
                <h1>Sign in</h1>
              </div>
              <div className={styles.fields}>
                {formError && <p className={styles.errorText}>{formError}</p>}

                <Controller
                  control={control}
                  name="username"
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      variant="standard"
                      label="Username or Email"
                      placeholder="e.g. Vincent van Gogh"
                      error={!!errors.username}
                      helperText={errors?.username?.message?.toString() || ""}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: "#000 !important",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "black",
                        },
                        "& .MuiInputBase-root": {
                          textAlign: "center",
                          fontSize: "16px",
                          fontFamily: "var(--font-signifier)",
                          color: "#000",
                          opacity: "1",
                        },
                      }}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: "username is required",
                  }}
                />

                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <PasswordTextField
                      error={errors.password}
                      helperText={errors?.password?.message?.toString()}
                      field={field}
                    />
                  )}
                  rules={{
                    required: "password is required",
                  }}
                />
              </div>

              <div className={styles.buttonsWrapper}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    dispatch(setAuthModalsState({ isLogInModalOpen: false }));
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={!isComplete || isSubmitting}>
                  {isSubmitting || !isComplete ? "Submitting..." : "Sign in"}
                </Button>
              </div>
            </div>
          </form>

          <div className={styles.secondaryButtonsWraper}>
            <Button
              onClick={() => {
                dispatch(setAuthenticationPhase(AuthPhase.FORGOT_PASSWORD_1));
              }}
              variant="text"
            >
              Forgot password?
            </Button>
            <Button
              variant="text"
              onClick={() => {
                dispatch(setAuthenticationPhase(AuthPhase.SIGNUP));
              }}
            >
              Sign up now
            </Button>
          </div>
          <div className={styles.separator}>OR</div>
          <div className={styles.googleSignInButtonWrapper}>
            <Button
              variant="socialSignIn"
              onClick={() => {
                handleGoogleLogin();
              }}
              icon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default LogInModal;
