import React from "react";
import Button from "./Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface BackButtonProps {
  onClick: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="text"
      onClick={() => {
        onClick();
      }}
    >
      <ArrowBackIcon />
    </Button>
  );
};

export default BackButton;
