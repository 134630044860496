"use client";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm, Controller, set } from "react-hook-form";

import { AuthPhase } from "@/types";
import styles from "./ForgotPassWordModal.module.scss";
import { TextField } from "@mui/material";
import Button from "@/components/Button/Button";
import AuthService from "@/services/authService";
import { useDispatch } from "react-redux";
import { setAuthenticationPhase } from "../../../app/GlobalRedux/Features/authSlice";
import BackButton from "@/components/Button/BackButton";
import Logging from "@/utils/Logging";

type FormValues = {
  email: string;
};

interface ForgotPassWordModalProps {
  setEmailForReset: Dispatch<SetStateAction<string>>;
}

const ForgotPassWordModal: React.FC<ForgotPassWordModalProps> = ({
  setEmailForReset,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
    },
  });
  const [formError, setFormError] = useState("");
  const [isComplete, setIsComplete] = useState(true);
  const dispatch = useDispatch();

  const handleSetDispatch = (authPhase: AuthPhase) => {
    dispatch(setAuthenticationPhase(authPhase));
  };

  const handleFormOneSubmit = async ({ email }: FormValues) => {
    try {
      const response = await AuthService.resetPasswordRequest(email);

      if (!response.success) {
        setFormError(
          response.remedy ||
            "There was an unknown error. Please try again or contact us at support@spawning.ai."
        );
        setIsComplete(true);
        return;
      }

      handleSetDispatch(AuthPhase.FORGOT_PASSWORD_2);
      setEmailForReset(email);
      setFormError("");

      setIsComplete(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsComplete(true);

      setFormError(
        "There was an unknown error. Please try again or contact us at support@spawning.ai."
      );
      Logging.networkError("API Error - Authservice.resetPasswordRequest", {
        pageName: "Forgot Password Modal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <BackButton onClick={() => handleSetDispatch(AuthPhase.LOGIN)} />
      <form
        onSubmit={handleSubmit(handleFormOneSubmit)}
        className={styles.form}
      >
        <h1>Get a reset code</h1>
        {formError && <p className={styles.errorText}>{formError}</p>}

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <TextField
              // makes first input focused when modal opens
              inputRef={(ref) => {
                ref?.focus();
              }}
              variant="standard"
              placeholder="email@example.com"
              label="Email"
              error={!!errors.email}
              helperText={errors?.email?.message?.toString() || ""}
              type="email"
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#000 !important",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "black",
                },
                "& .MuiInputBase-root": {
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "var(--font-signifier)",
                  color: "#000",
                  opacity: "1",
                },
              }}
              {...field}
            />
          )}
          rules={{
            required: "email is required",
          }}
        />

        <Button
          type="submit"
          disabled={
            Object.keys(errors).length > 0 || !isComplete || isSubmitting
          }
        >
          {isSubmitting || !isComplete ? "Submitting..." : "Send me an email"}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassWordModal;
