import React from "react";

const LogoIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1872.000000pt"
    height="1750.000000pt"
    viewBox="0 0 1872.000000 1750.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1750.000000) scale(0.100000,-0.100000)"
      fill="#fff"
      stroke="none"
    >
      <path
        d="M8815 17354 l-1050 -145 -1082 127 c-1053 124 -1083 127 -1103 109
  -43 -39 -34 -56 238 -407 141 -183 433 -560 648 -838 215 -278 398 -516 407
  -530 9 -14 104 -229 211 -479 l196 -455 0 -1543 0 -1543 -2266 0 -2266 0 -464
  190 -463 189 -865 646 c-584 436 -872 645 -888 645 -41 0 -63 -24 -61 -67 0
  -21 65 -507 143 -1080 l142 -1043 -127 -1077 -127 -1077 26 -23 c30 -26 42
  -28 72 -10 11 7 398 304 860 662 l839 650 459 197 459 198 2263 0 2264 0 0
  -2235 0 -2235 -2261 0 -2261 0 -468 192 -469 191 -862 643 c-473 354 -869 646
  -880 649 -22 7 -55 -9 -68 -33 -7 -13 34 -338 136 -1088 l145 -1069 -127
  -1073 c-118 -1003 -125 -1075 -110 -1098 37 -56 16 -70 928 636 l852 660 466
  200 466 200 2257 0 2256 0 0 -2595 0 -2595 495 0 495 0 0 7373 0 7372 193 470
  193 470 639 856 640 856 17 -28 c9 -15 304 -398 654 -851 351 -453 648 -841
  660 -863 12 -22 105 -231 206 -465 l183 -425 5 -7380 5 -7380 498 -3 497 -2 0
  7377 0 7376 189 464 190 463 643 862 c354 473 644 854 646 846 2 -9 301 -401
  665 -871 l662 -856 198 -463 198 -463 -1 -7367 0 -7368 495 0 495 0 1 7373 0
  7372 192 467 192 468 640 857 c352 471 644 863 648 870 13 22 0 61 -26 78 -21
  14 -114 3 -1092 -130 l-1069 -146 -1061 126 c-583 69 -1068 125 -1078 125 -9
  0 -26 -9 -36 -19 -19 -19 -19 -18 -25 9 -3 16 -14 34 -25 40 -16 8 -272 -24
  -1091 -135 l-1070 -146 -1063 126 c-584 69 -1070 125 -1079 125 -9 0 -24 -7
  -32 -16 -9 -9 -16 -11 -16 -4 0 18 -48 60 -67 59 -10 0 -490 -66 -1068 -145z"
      />
    </g>
  </svg>
);

export default LogoIcon;
