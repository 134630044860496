import React from "react";

interface SpawningArrowProps {
  className?: string;
}

const SpawningArrow = ({ className }: SpawningArrowProps) => (
  <svg
    className={className}
    version="1.1"
    id="a"
    xmlns="&ns_extend;"
    x="0px"
    y="0px"
    viewBox="0 0 183.2 210.6"
  >
    <switch>
      <foreignObject
        requiredExtensions="&ns_ai;"
        x="0"
        y="0"
        width="1"
        height="1"
      ></foreignObject>
      <g>
        <path
          d="M181.4,102.4c2.4,1,2.4,4.4,0,5.5L87,147.6c-0.2,0.1-0.5,0.2-0.7,0.4L4.9,210c-2.1,1.6-5.1-0.1-4.7-2.7L13,105.7
                c0-0.2,0-0.5,0-0.7L0,3.4C-0.3,0.8,2.7-1,4.8,0.6l81.5,61.9c0.2,0.2,0.4,0.3,0.6,0.4L181.4,102.4L181.4,102.4z"
        />
      </g>
    </switch>
  </svg>
);

export default SpawningArrow;
