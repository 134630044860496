import React, { useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";

import { TextField } from "@mui/material";
import Button from "@/components/Button/Button";
import AuthService from "@/services/authService";
import { useDispatch } from "react-redux";

import { setAuthModalsState } from "../../../../app/GlobalRedux/Features/authModalsSlice";

import styles from "./ForgotPasswordPhaseTwoModal.module.scss";
import PasswordFields from "@/components/PasswordFields/PasswordFields";
import { AuthPhase, ChallengeType, SessionChallenge } from "@/types";
import {
  setAuthenticationPhase,
  setUserInformation,
} from "../../../../app/GlobalRedux/Features/authSlice";
import { setSnackBar } from "../../../../app/GlobalRedux/Features/snackBarSlice";
import Logging from "@/utils/Logging";

type FormValues = {
  username: string;
  resetCode: string;
  password: string;
  confirmPassword: string;
};

interface ForgotPasswordPhaseTwoModalProps {
  emailForReset: string;
}

const ForgotPasswordPhaseTwoModal: React.FC<
  ForgotPasswordPhaseTwoModalProps
> = ({ emailForReset }) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      username: "",
      resetCode: "",
      password: "",
      confirmPassword: "",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  const dispatch = useDispatch();
  const [formError, setFormError] = useState("");
  const [isComplete, setIsComplete] = useState(true);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  const handleLogin = async (username: string, password: string) => {
    try {
      const response = await AuthService.login(
        username,
        password,
        (challenge: ChallengeType | undefined, params: SessionChallenge) => {
          if (challenge === ChallengeType.COMPLETE) {
            dispatch(setAuthenticationPhase(AuthPhase.DONE));

            dispatch(
              setUserInformation({
                username: username,
                access_token: params.access_token,
                id_token: params.id_token,
                refresh_token: params.refresh_token,
              })
            );
          } else if (challenge === "NEW_PASSWORD_REQUIRED") {
            dispatch(setAuthenticationPhase(AuthPhase.NEW_PASSWORD));
          } else if (challenge === "SOFTWARE_TOKEN_MFA") {
            dispatch(setAuthenticationPhase(AuthPhase.MFA_CHALLENGE));
          }

          return { challenge, parameters: params };
        }
      );

      setFormError(response.remedy ? response.remedy : "");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - authService.login", {
        pageName: "ForgotPasswordPhaseTwoModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  const handleFormSubmit = async ({
    username,
    password,
    confirmPassword,
    resetCode,
  }: FormValues) => {
    if (password !== confirmPassword) {
      setFormError("Passwords are not equal!");
      return;
    }

    try {
      const response = await AuthService.confirmResetPasswordRequest(
        username,
        password,
        resetCode
      );

      if (!response.success) {
        setFormError(
          response.remedy ||
            "There was an unknown error. Please try again or contact us at support@spawning.ai."
        );

        setIsComplete(true);
        return;
      }

      await handleLogin(username, password);
      setFormError("");
      dispatch(
        setSnackBar({ open: true, message: "Password successfully reset" })
      );
      setIsComplete(true);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setIsComplete(true);
      if (error.response.data) {
        setFormError(error.response.data.remedy);
        return;
      }

      setFormError(
        "There was an unknown error. Please try again or contact us at support@spawning.ai."
      );

      Logging.networkError("API Error - authService.confirmResetPassword", {
        pageName: "ForgotPasswordPhaseTwoModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
          <h1>Reset password</h1>
          <p>
            An email with a reset code has been sent to {emailForReset} You
            should receive it soon.
          </p>
          <div className={styles.fields}>
            {formError && <p className={styles.errorText}>{formError}</p>}
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField
                  variant="standard"
                  placeholder="e.g. VincentVanGogh123"
                  label="Username or Email"
                  error={!!errors.username}
                  helperText={errors?.username?.message?.toString() || ""}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#000 !important",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "var(--font-signifier)",
                      color: "#000",
                      opacity: "1",
                    },
                  }}
                  {...field}
                />
              )}
              rules={{
                required: "username is required",
              }}
            />

            <Controller
              control={control}
              name="resetCode"
              render={({ field }) => (
                <TextField
                  variant="standard"
                  label="Reset Code"
                  error={!!errors.resetCode}
                  helperText={errors?.resetCode?.message?.toString() || ""}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#000 !important",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "var(--font-signifier)",
                      color: "#000",
                      opacity: "1",
                    },
                  }}
                  {...field}
                />
              )}
              rules={{
                required: "resetCode is required",
              }}
            />

            <PasswordFields
              setIsPasswordValid={setIsPasswordValid}
              setIsConfirmPasswordValid={setIsConfirmPasswordValid}
            />
          </div>

          <Button
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              !isComplete ||
              isSubmitting ||
              !isPasswordValid ||
              !isConfirmPasswordValid ||
              !isDirty
            }
          >
            {isSubmitting || !isComplete
              ? "Submitting..."
              : " Set New Password"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(setAuthModalsState({ isLogInModalOpen: false }));
            }}
          >
            cancel
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default ForgotPasswordPhaseTwoModal;
