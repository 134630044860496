import React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";

interface ButtonProps {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "text" | "socialSignIn";
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  children,
  size = "medium",
  variant = "primary",
  type = "button",
  onClick,
  className,
  disabled,
  icon,
}) => {
  if (variant === "socialSignIn") {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={styles.socialSignInButton}
      >
        <div className={styles.socialSignInButtonState}></div>
        <div className={styles.socialSignInButtonContentWrapper}>
          <div className={styles.socialSignInButtonIcon}>
            {icon ? icon : null}
          </div>
          <div className={styles.socialSignInButtonContentWrapper}>
            <span className={styles.socialSignInButtonContents}>
              {children}
            </span>
          </div>
        </div>
      </button>
    );
  }

  if (variant === "text") {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={styles.textButton}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(className, styles.button, {
        [styles.secondary]: variant === "secondary",
        [styles.disabled]: disabled,
        [styles.small]: size === "small",
      })}
    >
      {children}
    </button>
  );
};

export default Button;
