"use client";

import React, { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import cn from "classnames";
import SearchBar from "../SearchBar/SearchBar";
import Link from "next/link";
import { useAppSelector } from "../../app/GlobalRedux/store";
import LoginPage from "../LoginPage/LoginPage";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import { setAuthModalsState } from "../../app/GlobalRedux/Features/authModalsSlice";
import { setAuthenticationPhase } from "../../app/GlobalRedux/Features/authSlice";
import { AuthPhase } from "@/types";
import AccountMenu from "../AccountMenu/AccountMenu";
import LogoAndHeader from "../../../public/assets/icons/LogoAndHeader";

import styles from "./NavBar.module.scss";
import LogoIcon from "../../../public/assets/icons/LogoIcon";

const NavBar = () => {
  const pathname = usePathname();
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const isAuthenticated = useAppSelector(
    (state) => state.auth.user?.access_token
  );

  const isLogInModalOpen = useAppSelector(
    (state) => state.authModals.isLogInModalOpen
  );

  const isExtensionsResultsPage = searchParams.get("materialize");

  useEffect(() => {
    // checks if OAuth process has produced an error, and if so opens the login modal
    const error = searchParams.get("error_description");
    if (error && error !== "null") {
      dispatch(setAuthModalsState({ isLogInModalOpen: true }));
      dispatch(setAuthenticationPhase(AuthPhase.LOGIN));
    }
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.navWrapper}>
      <div
        className={cn(styles.navContent, {
          [styles.navContentWithSearchBar]: pathname !== "/",
        })}
      >
        <div className={styles.navHeader}>
          <Link href="/">
            {pathname !== "/" || isExtensionsResultsPage ? (
              <>
                <div className={styles.logoWrapper}>
                  <LogoAndHeader />
                </div>
                <div className={styles.logoWrapperMobile}>
                  <LogoIcon />
                </div>
              </>
            ) : (
              <div className={styles.homePageLogo}>
                <LogoIcon />
              </div>
            )}
          </Link>
        </div>

        {(pathname !== "/" || isExtensionsResultsPage) && (
          <div>
            <SearchBar
              className={styles.navSearchBar}
              dropDownMenuClassName={styles.dropDownMenu}
            />
          </div>
        )}
        {isLogInModalOpen && <LoginPage />}
        {isAuthenticated ? (
          <AccountMenu />
        ) : (
          <Button
            className={styles.signInButton}
            variant="secondary"
            onClick={() => {
              dispatch(setAuthenticationPhase(AuthPhase.LOGIN));
              dispatch(setAuthModalsState({ isLogInModalOpen: true }));
            }}
          >
            Sign in
          </Button>
        )}
      </div>
    </div>
  );
};

export default NavBar;
