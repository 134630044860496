"use client";
import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FieldError, ControllerRenderProps } from "react-hook-form";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import PasswordStrengthMeter from "@/components/design-components/PasswordStrengthMeter/PasswordStrengthMeter";

interface PasswordTextFieldProps {
  error?: FieldError;
  helperText?: string;
  label?: string;
  showPasswordValidation?: boolean;
  field: ControllerRenderProps<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  setIsPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordTextField: React.FC<PasswordTextFieldProps> = ({
  error,
  helperText,
  label = "Password",
  showPasswordValidation = false,
  field,
  setIsPasswordValid,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { value } = field;

  return (
    <>
      {/* for some reason having this here turns off the autofill for the textfield */}
      <input
        type="text"
        style={{
          display: "none",
        }}
      />
      <TextField
        label={label}
        type={showPassword ? "text" : "password"}
        variant="standard"
        placeholder="*******"
        error={!!error}
        helperText={helperText}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiFormLabel-root": {
            color: "#000 !important",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
          "& .MuiInputBase-root": {
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "var(--font-signifier)",
            color: "#000",
            opacity: "1",
          },
        }}
        {...field}
      />

      {showPasswordValidation && (
        <PasswordStrengthMeter
          password={value}
          setIsPasswordValid={setIsPasswordValid}
        />
      )}
    </>
  );
};

export default PasswordTextField;
