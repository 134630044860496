import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import Button from "@/components/Button/Button";
import AuthService from "@/services/authService";
import { useDispatch } from "react-redux";

import { setAuthModalsState } from "../../../app/GlobalRedux/Features/authModalsSlice";

import styles from "./NewPasswordModal.module.scss";
import PasswordFields from "@/components/PasswordFields/PasswordFields";
import { AuthPhase } from "@/types";
import { setAuthenticationPhase } from "../../../app/GlobalRedux/Features/authSlice";
import { setSnackBar } from "../../../app/GlobalRedux/Features/snackBarSlice";
import { useAppSelector } from "@/app/GlobalRedux/store";
import Logging from "@/utils/Logging";

type FormValues = {
  password: string;
  confirmPassword: string;
};

const NewPassWordModal = () => {
  const user = useAppSelector((state) => state.auth.user);
  const methods = useForm<FormValues>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const { handleSubmit } = methods;

  const dispatch = useDispatch();
  const [formError, setFormError] = useState("");

  const handleFormSubmit = async ({
    password,
    confirmPassword,
  }: FormValues) => {
    if (password !== confirmPassword) {
      setFormError("Passwords are not equal!");
      return;
    }

    try {
      await AuthService.completeNewPassword(password, user?.session || "");

      dispatch(setSnackBar({ open: true, message: "Password reset" }));
      dispatch(setAuthenticationPhase(AuthPhase.LOGIN));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Logging.networkError("API Error - authService.confirmResetPassword", {
        pageName: "NewPasswordModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className={styles.form}>
          <h1>Please reset your password</h1>
          <div className={styles.fields}>
            {formError && <p className={styles.errorText}>{formError}</p>}

            <PasswordFields />
          </div>

          <Button type="submit">Set New Password</Button>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(setAuthModalsState({ isLogInModalOpen: false }));
            }}
          >
            cancel
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewPassWordModal;
