import React from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import PasswordTextField from "./PasswordTextField/PasswordTextField";
import ConfirmPasswordTextField from "./ConfirmPasswordTextField/ConfirmPasswordTextField";

interface PasswordFieldsProps {
  label?: string;
  confirmLabel?: string;
  setIsPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordFields: React.FC<PasswordFieldsProps> = ({
  label,
  confirmLabel,
  setIsPasswordValid,
  setIsConfirmPasswordValid,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const passwordFieldValue = watch("password");

  return (
    <>
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <PasswordTextField
            label={label}
            error={errors.password as FieldError}
            helperText={errors?.password?.message?.toString()}
            showPasswordValidation
            field={field}
            setIsPasswordValid={setIsPasswordValid}
          />
        )}
        rules={{
          required: "password is required",
        }}
      />

      <Controller
        control={control}
        name="confirmPassword"
        render={({ field }) => (
          <ConfirmPasswordTextField
            label={confirmLabel || "Confirm Password"}
            error={errors.password as FieldError}
            helperText={errors?.password?.message?.toString()}
            showPasswordValidation
            field={field}
            setIsConfirmPasswordValid={setIsConfirmPasswordValid}
            passwordFieldValue={passwordFieldValue}
          />
        )}
        rules={{
          required: "confirm password is required",
        }}
      />
    </>
  );
};

export default PasswordFields;
