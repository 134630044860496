"use client";
import React, { useEffect, useState } from "react";
import cn from "classnames";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FieldError, ControllerRenderProps } from "react-hook-form";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./ConfirmPasswordTextField.module.scss";

interface ConfirmPasswordTextFieldProps {
  error?: FieldError;
  helperText?: string;
  label?: string;
  showPasswordValidation?: boolean;
  field: ControllerRenderProps<any, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  passwordFieldValue: string;
  setIsConfirmPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmPasswordTextField: React.FC<ConfirmPasswordTextFieldProps> = ({
  error,
  helperText,
  label = "Password",
  showPasswordValidation = false,
  field,
  passwordFieldValue,
  setIsConfirmPasswordValid,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const { value } = field;

  useEffect(() => {
    const match = passwordFieldValue === value;

    setPasswordsMatch(match);

    if (setIsConfirmPasswordValid) {
      setIsConfirmPasswordValid(match);
    }
  }, [passwordFieldValue, value]); // eslint-disable-line react-hooks/exhaustive-deps

  const determinPasswordRuleToShow = (
    rule: boolean,
    successMessage: string,
    failMessage: string
  ) => {
    const SuccessCheckIcon = () => (
      <CheckIcon
        fontSize="small"
        sx={{
          color: "var(--success-text)",
        }}
      />
    );

    const FailCheckIcon = () => (
      <CloseIcon
        fontSize="small"
        sx={{
          color: "var(--error-text)",
        }}
      />
    );

    return (
      <div
        className={cn(styles.passwordRule, {
          [styles.passwordRuleSuccess]: rule,
        })}
      >
        {rule ? <SuccessCheckIcon /> : <FailCheckIcon />}{" "}
        <p className={styles.passwordRuleText}>
          {rule ? successMessage : failMessage}
        </p>
      </div>
    );
  };

  return (
    <>
      {/* for some reason having this here turns off the autofill for the textfield */}
      <input
        type="text"
        style={{
          display: "none",
        }}
      />
      <TextField
        label={label}
        type={showPassword ? "text" : "password"}
        variant="standard"
        placeholder="*******"
        error={!!error}
        helperText={helperText}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiFormLabel-root": {
            color: "#000 !important",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "black",
          },
          "& .MuiInputBase-root": {
            textAlign: "center",
            fontSize: "16px",
            fontFamily: "var(--font-signifier)",
            color: "#000",
            opacity: "1",
          },
        }}
        {...field}
      />

      {showPasswordValidation && (
        <div>
          {determinPasswordRuleToShow(
            !!(passwordsMatch && passwordFieldValue),
            "Passwords match",
            "Passwords do not match"
          )}
        </div>
      )}
    </>
  );
};

export default ConfirmPasswordTextField;
