"use client";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AuthPhase } from "@/types";
import { useAppSelector } from "../../app/GlobalRedux/store";
import { setAuthModalsState } from "../../app/GlobalRedux/Features/authModalsSlice";

import SignUpModal from "./SignUpModal/SignUpModal";
import ForgotPassWordModal from "./ForgotPassWordModal/ForgotPassWordModal";
import LogInModal from "./LogInModal/LogInModal";
import ForgotPasswordPhaseTwoModal from "./ForgotPassWordModal/ForgotPasswordPhaseTwoModal/ForgotPasswordPhaseTwoModal";
import NewPassWordModal from "./NewPasswordModal/NewPasswordModal";

import styles from "./LoginPage.module.scss";

const LoginPage = () => {
  const [emailForReset, setEmailForReset] = useState<string>("");
  const dispatch = useDispatch();
  const authPhase = useAppSelector((state) => state.auth.authPhase);

  useEffect(() => {
    if (authPhase === AuthPhase.DONE) {
      dispatch(setAuthModalsState({ isLogInModalOpen: false }));
    }
  }, [authPhase]); // eslint-disable-line react-hooks/exhaustive-deps

  const authPhaseElement = {
    [AuthPhase.LOGIN]: <LogInModal />,
    [AuthPhase.SIGNUP]: <SignUpModal />,
    [AuthPhase.NEW_PASSWORD]: <NewPassWordModal />,

    [AuthPhase.FORGOT_PASSWORD_1]: (
      <ForgotPassWordModal setEmailForReset={setEmailForReset} />
    ),
    [AuthPhase.FORGOT_PASSWORD_2]: (
      <ForgotPasswordPhaseTwoModal emailForReset={emailForReset} />
    ),
    // this is not supported yet and will need more work in the future
    [AuthPhase.MFA_CHALLENGE]: (
      <div>{/* <Button onClick={onSubmitMfa}>Submit</Button> */}</div>
    ),
    [AuthPhase.DONE]: (
      <div className={styles.successStateWrapper}>
        <h3>You have successfully logged in!</h3>
      </div>
    ),

    [AuthPhase.LOGOUT]: null,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>{authPhaseElement[authPhase]}</div>
      </div>
    </div>
  );
};

export default LoginPage;
