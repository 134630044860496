"use client";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/navigation";
import {
  TextField,
  Box,
  IconButton,
  MenuList,
  MenuItem,
  ListItemText,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import styles from "./SearchBar.module.scss";
import cn from "classnames";
import { DomainAutoCompleteResponse, SearchType } from "@/types";
import HibtService from "@/services/hibtService";
import { setCurrentSearch } from "../../app/GlobalRedux/Features/searchSlice";
import { AppDispatch } from "../../app/GlobalRedux/store";
import { getSearchUrl } from "@/utils/utils";
import mixpanel from "mixpanel-browser";
import Logging from "@/utils/Logging";

const FILE_SIZE_LIMIT = 10;

interface SearchBarProps {
  className?: string;
  dropDownMenuClassName?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  className,
  dropDownMenuClassName,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const menuItemsRefs = useRef<(HTMLLIElement | null)[]>([]);
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();

  const [totalMenuItems, setTotalMenuItems] = useState(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [domainAutocompleteOptions, setDomainAutocompleteOptions] = useState<
    DomainAutoCompleteResponse[]
  >([]);
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState<boolean>(false);
  const [focusedMenuItem, setFocusedMenuItem] = useState<number | null>(null);
  const [imageUploadError, setImageUploadError] = useState("");

  useEffect(() => {
    if (searchValue.length >= 3) {
      setIsDropDownMenuOpen(true);
    }

    if (searchValue.length < 3) {
      setIsDropDownMenuOpen(false);
      setFocusedMenuItem(null);
    }
  }, [searchValue]);

  useEffect(() => {
    if (imageUploadError) {
      setTimeout(() => {
        setImageUploadError("");
      }, 5000);
    }
  }, [imageUploadError]);

  useEffect(() => {
    const handleGetDomainAutoCompleteOptions = async () => {
      try {
        const removedSpaces = searchValue.replace(/\s/g, "");
        const domainData = await HibtService.searchDomains(
          removedSpaces,
          10,
          0
        );

        if (!domainData) {
          return null;
        }

        setDomainAutocompleteOptions(domainData.records);
        setTotalMenuItems(2 + domainData.records.length); // 2 for static items

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Logging.networkError("API Error - getDomainAutoCompleteOptions", {
          pageName: "DomainSearchResultsPage",
          errorMessage: error.message,
          errorCode: error.code,
          statusCode: error.response?.status,
        });
      }
    };

    if (searchValue.length >= 3) {
      handleGetDomainAutoCompleteOptions();
    }
  }, [searchValue.length >= 3, searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (focusedMenuItem !== null) {
        // Prevent default action and return without triggering text search
        e.preventDefault();
        return;
      }
      handleSubmitSearch(SearchType.TEXT, searchValue);
    }

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      if (focusedMenuItem === null) {
        setFocusedMenuItem(0);
        menuItemsRefs.current[0]?.focus();
        return;
      }

      let nextIndex;
      if (e.key === "ArrowDown") {
        nextIndex = (focusedMenuItem + 1) % totalMenuItems;
      } else {
        nextIndex =
          focusedMenuItem - 1 < 0 ? totalMenuItems - 1 : focusedMenuItem - 1;
      }

      setFocusedMenuItem(nextIndex);
      menuItemsRefs.current[nextIndex]?.focus();
    }
  };

  const handleSubmitSearch = (
    searchType: SearchType,
    valueToSearch: string
  ) => {
    if (!valueToSearch) {
      return;
    }

    dispatch(
      setCurrentSearch({
        type: searchType,
        value: valueToSearch,
      })
    );

    mixpanel.track(`Search action`, {
      type: searchType,
      value: valueToSearch,
    });

    setIsDropDownMenuOpen(false);

    const route = getSearchUrl(searchType, valueToSearch);

    router.push(route);
  };

  const handleItemKeyDown = (
    e: React.KeyboardEvent<HTMLLIElement>,
    type: SearchType,
    value: string
  ) => {
    if (e.key === "Enter") {
      handleSubmitSearch(type, value);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > FILE_SIZE_LIMIT * 1024 * 1024) {
        setImageUploadError(`File size is too large. Please try a file smaller than
        ${FILE_SIZE_LIMIT} Mb`);
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const value = (reader.result as string).split(",")[1];

        dispatch(
          setCurrentSearch({
            type: SearchType.IMAGE,
            value: value,
            thumb: reader.result,
          })
        );

        mixpanel.track(`Search action`, {
          type: SearchType.IMAGE,
        });

        const route = getSearchUrl(SearchType.IMAGE, value);
        event.preventDefault();
        router.push(route);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onerror = (error: any) => {
        Logging.error(
          "Error reading file",
          "SearchBar uplaod image",
          error.message
        );
      };
    }
  };

  return (
    <Box className={className} onKeyDown={handleKeyDown}>
      {/* for some reason having this here turns off the autofill for the textfield */}
      <input
        type="text"
        style={{
          display: "none",
        }}
      />
      <TextField
        className={styles.searchBar}
        placeholder="Search for images, domains, and more..."
        InputProps={{
          startAdornment: (
            <SearchIcon sx={{ color: "black", margin: "10px" }} />
          ),
        }}
        onChange={(e) => setSearchValue(e.target.value)}
        error={!!imageUploadError}
        helperText={imageUploadError}
        sx={{
          "& p": {
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "var(--font-signifier)",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        }}
      />
      <ClickAwayListener
        onClickAway={() => {
          setIsDropDownMenuOpen(false);
          setFocusedMenuItem(null);
        }}
      >
        <MenuList
          className={cn(styles.dropdownMenu, dropDownMenuClassName, {
            [styles.hidden]: !isDropDownMenuOpen,
          })}
        >
          <ListItemText className={styles.dropdownMenuSectionHeader}>
            Search by text
          </ListItemText>
          <MenuItem
            className={styles.dropdownItem}
            ref={(el) => (menuItemsRefs.current[0] = el)}
            onClick={() => handleSubmitSearch(SearchType.TEXT, searchValue)}
            onKeyDown={(e) =>
              handleItemKeyDown(e, SearchType.TEXT, searchValue)
            }
          >
            <ListItemText className={styles.dropDownItemText}>
              {searchValue}
            </ListItemText>
          </MenuItem>
          {/* We removed book functionality for now */}
          {/* <ListItemText className={styles.dropdownMenuSectionHeader}>
            Search Books, Magazines, and other Texts
          </ListItemText>
          <MenuItem
            className={styles.dropdownItem}
            ref={(el) => (menuItemsRefs.current[1] = el)}
            onClick={() =>
              handleSubmitSearch(SearchType.LITERATURE, searchValue)
            }
          >
            <ListItemText>{searchValue}</ListItemText>
          </MenuItem> */}
          {domainAutocompleteOptions.length > 0 && (
            <span>
              <ListItemText className={styles.dropdownMenuSectionHeader}>
                Search by domain
              </ListItemText>
              {domainAutocompleteOptions.map(({ domain, count }, idx) => {
                return (
                  <MenuItem
                    key={`${domain}-${idx}`}
                    className={cn(styles.domainWrapper, styles.dropdownItem)}
                    ref={(el) => (menuItemsRefs.current[idx + 1] = el)}
                    onClick={() =>
                      handleSubmitSearch(SearchType.DOMAIN, domain)
                    }
                    onKeyDown={(e) =>
                      handleItemKeyDown(e, SearchType.DOMAIN, domain)
                    }
                  >
                    <ListItemText className={styles.dropDownItemText}>
                      {domain}
                    </ListItemText>
                    <ListItemText className={styles.total}>
                      {`${count} ${count === 1 ? "image" : "images"} found`}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </span>
          )}
        </MenuList>
      </ClickAwayListener>
    </Box>
  );
};

export default SearchBar;
