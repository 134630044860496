import React, { useEffect } from "react";
import zxcvbn from "zxcvbn";
import cn from "classnames";
import InfoIcon from "@mui/icons-material/Info";

import styles from "./PasswordStrengthMeter.module.scss";
import { Tooltip } from "@mui/material";

const MIN_LENGTH = 8;
const MAX_LENGTH = 99;
const MINIMUM_PASSWORD_STRENGTH = 3;

interface PasswordStrengthMeterProps {
  password: string;
  setIsPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
  setIsPasswordValid,
}) => {
  const testResult = zxcvbn(password);

  const feedBack = testResult.feedback;

  useEffect(() => {
    if (!setIsPasswordValid) return;

    if (testResult.score > 2) {
      setIsPasswordValid(true);
      return;
    }

    if (
      password.length < MIN_LENGTH ||
      password.length > MAX_LENGTH ||
      feedBack.warning
    ) {
      setIsPasswordValid(false);
      return;
    }

    setIsPasswordValid(false);
  }, [password, feedBack.warning, testResult.score]); // eslint-disable-line react-hooks/exhaustive-deps

  const strengthInfo = {
    0: {
      text: "Password is not strong enough",
      tooltipText: feedBack.suggestions.join("\n"),
    },
    1: {
      text: "Password is not strong enough",
      tooltipText: feedBack.suggestions.join("\n"),
    },
    2: {
      text: "Password is not strong enough",
      tooltipText: feedBack.suggestions.join("\n"),
    },
    3: {
      text: "Strong Password",
      tooltipText: "",
    },
    4: {
      text: "Very Strong Password",
      tooltipText: "",
    },
  };

  const strengthClass = cn({
    [styles.veryWeak]: testResult.score === 0,
    [styles.weak]: testResult.score === 1,
    [styles.fair]: testResult.score === 2,
    [styles.good]: testResult.score === 3,
    [styles.strong]: testResult.score === 4,
  });

  const progressBarWidth = (testResult.score * 100) / 4;

  const determineMessage = () => {
    if (password.length === 0) {
      return "Password is required";
    }

    if (feedBack.warning) {
      return feedBack.warning;
    }

    if (password.length < MIN_LENGTH) {
      return "Password is not long enough";
    }

    if (password.length >= MAX_LENGTH) {
      return "Password exceeds the max limit";
    }

    return strengthInfo[testResult.score].text;
  };

  return (
    <div className={styles.container}>
      <div className={styles.progressBarWrapper}>
        <div
          className={cn(styles.progressBar, strengthClass, {
            [styles.warning]:
              feedBack.warning ||
              password.length >= MAX_LENGTH ||
              password.length < MIN_LENGTH,
          })}
          style={{
            width: `${progressBarWidth}%`,
          }}
        ></div>
      </div>

      <div className={styles.passwordMessageWrapper}>
        <p
          className={cn(styles.passwordInfoText, {
            [styles.warningText]: feedBack.warning,
          })}
        >
          {determineMessage()}{" "}
        </p>
        {testResult.score < MINIMUM_PASSWORD_STRENGTH && !feedBack.warning && (
          <Tooltip
            placement="bottom"
            title={
              password.length < MIN_LENGTH
                ? `Password must be at least ${MIN_LENGTH} characters long.`
                : strengthInfo[testResult.score].tooltipText
            }
            PopperProps={{ style: { zIndex: 999999, width: "150px" } }}
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
