import React, { useState } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FormProvider,
} from "react-hook-form";
import { TextField } from "@mui/material";

import Button from "@/components/Button/Button";

import styles from "./SignUpModal.module.scss";
import { useDispatch } from "react-redux";
import {
  setAuthenticationPhase,
  setUserInformation,
} from "../../../app/GlobalRedux/Features/authSlice";
import { AuthPhase, ChallengeType, SessionChallenge } from "@/types";
import AuthService from "@/services/authService";
import BackButton from "@/components/Button/BackButton";
import PasswordFields from "@/components/PasswordFields/PasswordFields";
import mixpanel from "mixpanel-browser";
import Logging from "@/utils/Logging";

type SignUpModalFormValues = {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
};

const SignUpModal = () => {
  const methods = useForm<SignUpModalFormValues>({
    defaultValues: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  const dispatch = useDispatch();
  const [formError, setFormError] = useState("");
  const [isComplete, setIsComplete] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);

  const handleSetDispatch = (authPhase: AuthPhase) => {
    dispatch(setAuthenticationPhase(authPhase));
  };

  const handleFormSubmit: SubmitHandler<SignUpModalFormValues> = async ({
    email,
    username,
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) {
      setFormError("Passwords are not equal!");
      return;
    }

    try {
      const response = await AuthService.signup(
        username,
        email,
        password,
        (challenge: ChallengeType | undefined, params: SessionChallenge) => {
          setIsComplete(false);
          setFormError("");

          if (challenge === "COMPLETE") {
            handleSetDispatch(AuthPhase.DONE);
            mixpanel.track("Sign Up", {
              username: username,
              email: email,
            });

            // IMPORTANT!
            // Use the access_token to talk to the auth endpoints
            // use the id_token to talk to the HIBT endpoints

            dispatch(
              setUserInformation({
                username: username,
                access_token: params.access_token,
                id_token: params.id_token,
                refresh_token: params.refresh_token,
              })
            );
          } else if (challenge === "NEW_PASSWORD_REQUIRED") {
            handleSetDispatch(AuthPhase.NEW_PASSWORD);
          }

          return { challenge, parameters: params };

          // We will support this later
          // else if (challenge === "SOFTWARE_TOKEN_MFA") {
          //   handleSetDispatch(AuthPhase.MFA_CHALLENGE);
          //   // setNextStepSession(params.session);
          // }
        }
      );

      setFormError(response.remedy || "");
      setIsComplete(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.remedy;
        setFormError(errorMessage);

        return;
      }

      setFormError(
        "There was an unknown error logging in. Please try again or contact us at support@spawning.ai."
      );

      setIsComplete(true);
      Logging.networkError("API Error - authService.signup", {
        pageName: "SignUpModal",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <BackButton onClick={() => handleSetDispatch(AuthPhase.LOGIN)} />
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit(handleFormSubmit)}>
          <h2>Sign up for an account</h2>
          <div className={styles.fields}>
            {formError && <p className={styles.errorText}>{formError}</p>}
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  variant="standard"
                  label="Email"
                  placeholder="e.g. Vincent@vanGogh.com"
                  error={!!errors.email}
                  helperText={errors?.email?.message?.toString() || ""}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#000 !important",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "var(--font-signifier)",
                      color: "#000",
                      opacity: "1",
                    },
                  }}
                  {...field}
                />
              )}
              rules={{
                required: "email is required",
              }}
            />
            <Controller
              control={control}
              name="username"
              render={({ field }) => (
                <TextField
                  variant="standard"
                  label="Username"
                  placeholder="e.g. VincentVanGogh123"
                  error={!!errors.username}
                  helperText={errors?.username?.message?.toString() || ""}
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#000 !important",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black",
                    },
                    "& .MuiInputBase-root": {
                      textAlign: "center",
                      fontSize: "16px",
                      fontFamily: "var(--font-signifier)",
                      color: "#000",
                      opacity: "1",
                    },
                  }}
                  {...field}
                />
              )}
              rules={{
                required: "username is required",
              }}
            />

            <PasswordFields
              setIsPasswordValid={setIsPasswordValid}
              setIsConfirmPasswordValid={setIsConfirmPasswordValid}
            />
          </div>

          <Button
            type="submit"
            disabled={
              Object.keys(errors).length > 0 ||
              !isComplete ||
              isSubmitting ||
              !isPasswordValid ||
              !isConfirmPasswordValid ||
              !isDirty
            }
          >
            {isSubmitting || !isComplete ? "Submitting..." : "Sign up"}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default SignUpModal;
